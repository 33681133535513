.masonry-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000px;
}

.masonry-container div.resume-box {
    padding-top: 15px;
    margin: 0 1rem 1rem 0;
}

.resume-row {
    background: #0b0b13;
    padding: 35px;
    border-radius: 15px;
}

.resume-row .rb-left {
    min-height: 100%;
}

.resume-row .rb-left h6 {
    margin-bottom: 5px;
}

.theme-dark .resume-row .rb-left label {
    color: #fff;
}

.resume-row .rb-left label {
    font-size: 14px;
    font-style: italic;
}

.resume-row .rb-left p {
    margin-top: 5px;
    margin-bottom: 8px;
    line-height: normal;
}

.resume-row .rb-left .rb-time {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
    background: #ff9301;
    font-size: 12px;
    font-weight: 500;
}

.resume-box .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 3px var(--brand-color-green);
    background-color: var(--bs-gray);
    margin-bottom: 10px;
}

@media (max-width: 767px) {

    .masonry-container {
        flex-direction: inherit;
        max-height: fit-content;
    }

    .resume-row .rb-left {
        display: flex;
        margin-bottom: 0;
    }

    .resume-row .rb-left .details{
        margin: 0 auto;
        line-height: 60px;
    }
}