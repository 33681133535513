.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .85);
    opacity: .75;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    z-index: 1000 !important;
}