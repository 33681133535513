.section-services {

}

.section-services .title h3,
.section-services .title .has-bullet {
    color: var(--brand-color-dark-light) !important;
}

.theme-dark .section-services h1,
.theme-dark .section-services h2,
.theme-dark .section-services h3,
.theme-dark .section-services h4,
.theme-dark .section-services h5,
.theme-dark .section-services h6 {
    color: var(--brand-color-dark-light) !important;
}

.theme-dark .section-services .meta,
.theme-dark .section-services p,
.theme-dark .section-services span {
    color: var(--brand-color-dark-light) !important;
}

.service-box {
    background: var(--brand-color-white-speculative);
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: 15px;
    color: initial;
}

.service-box .title {
    font-weight: 700;
    font-size: 1.75em;
}

.service-content .caption {
    text-align: justify;
    font-size: 1.15em;
}

.service-content .advise {
    font-style: italic;
    font-size: 1em;
}

.service-content .price-content{
    margin-bottom: 25px;
}

.service-content .setting {
    font-size: 1.15em;
    margin-bottom: 5px;
}

.service-content .setting label {
    text-transform: lowercase;
    font-weight: 600;
}

.service-content .setting label:first-letter {
    text-transform: uppercase;
}

.service-content .price small {
    font-size: .55em;
}


@media (max-width: 767px) {
    .service-box {
        padding: 25px 15px;
    }

    .service-box .title {
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 15px;
    }

    .service-content .caption,
    .service-content .setting {
        text-align: left;
        font-size: 1em;
    }

    .service-content .price-content{
        text-align: center;
        margin: 15px 0;
    }

    .service-box .px-btn{
        width: 100%;
    }
}