.education-box {
    margin: 0;
    padding: 35px;
    border-radius: 10px;
    /*background: #0b0b13;*/
    background: #000;
    list-style: none;
}

.skill-lt {
    position: relative;
    padding-left: 80px;
}

.skill-lt:not(:first-child) {
    margin-top: 45px;
}

.skill-lt h6 {
    font-size: 15px;
    margin: 0 0 10px;
    font-weight: 400;
    color: #fff;
}

.skill-lt .count-inner {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    left: 0;
    top: 8px;
    position: absolute;
}

.skill-lt .skill-bar {
    position: relative;
    background: hsla(0,0%,100%,.2);
}

.skill-lt .skill-bar .skill-bar-in {
    width: 80px;
    position: relative;
    transition: all .55s ease;
    height: 10px;
    background: #ff9301;
}

@media (max-width: 767px) {
    .education-box p {
        text-align: justify;
    }
}