.motto{
    max-width: 80%;
}

@media (max-width: 767px) {
    .motto {
        text-align: center;
        max-width: 100%;
    }

    .banner .type-box .desc.h-width {
        text-align: center;
        max-width: 100%;
    }
}