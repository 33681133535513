.clients-wrapper .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    background: var(--brand-color-white);
    padding: .5em;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.preview-inner .slick-list, .testimonial-wrapper .slick-list {
    margin: 0 -10px;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:after, .slick-track:before {
    display: table;
    content: "";
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide .avatar {
    height: 100%;
}

.clients-wrapper .slick-slide img {
    display: block;
    height: 180px;
    object-fit: contain;
    margin: 0 auto;
}

.preview-inner .slick-slide > div, .testimonial-wrapper .slick-slide > div {
    margin: 0 10px;
}

.theme-light .client-01 {
    background: #fff;
}

.theme-dark .client-box-01, .theme-dark .client-box-02, .theme-dark .client-01 {
    background: #010101;
}

.client-01 {
    background: #0b0b13;
    padding: 35px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 25px;
}

.client-01 .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 767px) {
    .client-01 .avatar {
        width: 50px;
        height: 50px;
    }
}

.client-01 span {
    font-size: 13px;
}

.client-01 h6 {
    color: #fff;
    margin: 0 0 5px;
}

.client-01 .media-body {
    padding-left: 25px;
}

.media {
    display: flex !important;
    align-items: flex-start;
}


.slick-dots {
    bottom: -30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    margin: 0 3px;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000;
}

.slick-dots li button:after, .slick-dots li button:before {
    content: "";
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}

.slick-dots li button:after {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    background: #ff9301;
    border-radius: inherit;
    z-index: 5;
}

.slick-dots li button:before {
    font-size: 0;
    opacity: 1;
    border: 2px solid #ff9301;
    border-radius: 50%;
}

.slick-dots li button:before {
    font-family: "slick", serif;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:after {
    -webkit-transform: scale(.6);
    transform: scale(.6);
}

.slick-arrow {
    display: none !important;
}