.header-left {
    z-index: 1;
    width: 80px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    background: #0c0c14;
    border-right: 1px solid rgba(11, 11, 19, .1);
}

.theme-light .header-left {
    background: #fff;
}

.header-left .scroll-bar {
    height: 100%
}

@media (max-width: 767px) {
    .header-left .scroll-bar {
        display: flex;
        flex-direction: column;
        justify-content: start; /*space-between;*/
    }
}

.header-left .hl-top a {
    background: var(--bs-white);
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    font-size: 40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

.header-left .hl-top a img {
    padding: 10px;
}

.header-left .nav-menu {
    padding-top: 45px;
    padding-bottom: 45px;
}

.header-left .nav-menu li {
    width: 100%
}

.header-left .nav-menu li + li {
    border-top: 1px solid hsla(0, 0%, 100%, .1);
}

.theme-light .header-left .nav-menu li + li {
    border-top: 1px solid rgba(11, 11, 19, .1);
}

.header-left .nav-menu li .nav-link {
    margin: 0;
    padding: 10px 0;
    color: #fff;
    text-decoration: none;
    transition: all .35s ease;
    text-align: center;
    font-size: 27px;
}

.theme-light .header-left .nav-menu li .nav-link {
    color: #333;
}

.header-left .nav-menu li .nav-link i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 25px;
}


.header-left.header-menu-style-two .nav-menu li .nav-link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 13px 0 10px;
    height: 65px;
    place-content: center;
}

.header-left.header-menu-style-two .nav-menu li .nav-link:after {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 0;
    top: auto;
    bottom: 0;
    background: #ff9301;
    transition: .3s;
}

@media (min-width: 768px) {
    .header-left .nav-menu li:hover {
        color: #ff9301;
    }
}

.header-left .nav-menu li.active .nav-link,
.header-left .nav-menu li.active span {
    color: #ff9301;
}

.header-left .nav-menu li .nav-link.active ,
.header-left .nav-menu li span.active  {
    color: #ff9301;
}

.header-left.header-menu-style-two .nav-menu li .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 13px 0 10px;
}

.header-left.header-menu-style-two .nav-menu li .nav-link:after {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 0;
    top: auto;
    bottom: 0;
    background: #ff9301;
    transition: .3s;
}

.header-left.header-menu-style-two .nav-menu li .nav-link .item {
    font-size: 15px;
    padding-top: 3px;
    display: none;
}

.header-left.header-menu-style-two .nav-menu li.active .nav-link ,
.header-left.header-menu-style-two .nav-menu li .nav-link.active {
    font-weight: 400;
}

.header-left.header-menu-style-two .nav-menu li.active .nav-link:after,
.header-left.header-menu-style-two .nav-menu li .nav-link.active:after{
    height: 100%;
    bottom: auto;
    top: 0;
}

.header-left .social-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    display: none;
}

.header-left .social-icons a {
    color: #fff;
    font-size: 14px;
    margin-right: 17px;
}

.header-left .social-icons a:hover {
    color: #ff9301;
}

.theme-dark .header-left {
    border-right: 1px solid hsla(0, 0%, 100%, .1);
}

.theme-dark .header-left .nav-menu li + li {
    border-top-color: hsla(0, 0%, 100%, .1);
}

@media (max-width: 767px) {
    .header-left {
        transition: all .35s ease;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        z-index: 222;
    }

    .header-left.menu-open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}


/* Menu Burger */
.mob-header {
    display: none;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 11;
}

.toggler-menu {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 50%;
    padding: 0;
    background: var(--brand-color-orange);
    border: none;
    cursor: pointer;
    margin-left: auto;
}

.toggler-menu span:first-child {
    top: -17px;
}

.toggler-menu span:nth-child(2) {
    top: 2px;
}

.toggler-menu span:nth-child(3) {
    top: auto;
    bottom: 19px;
}

.toggler-menu span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 25px;
    height: 2px;
    margin: auto;
    background: #fff;
    color: #fff;
}

.toggler-menu span {
    transition: .3s;
}

@media (max-width: 767px) {
    .mob-header {
        display: block;
    }
}