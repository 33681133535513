.modal {
    position: initial;
    top: initial;
    left: inherit;
    width: clamp(50%, 900px, 90%);
    height: min(70%, 1200px);
    margin: auto;
    padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-header h3 {
    color: var(--brand-color-dark) !important;
}

.modal-header .px-btn.close {
    padding: 0.5rem;
    border-radius: 100%;
    border: solid 1px #000;
    line-height: 11px;
}

.modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.modal-button {
    position: relative;
    padding: 0 3rem;
    min-height: 3rem;
    margin: auto auto 0 auto;
    background: var(--brand-color-green);
    border: solid 1px var(--brand-color-green-speculative);
    color: var(--brand-color-white);
}

.modal-form input {
    width: 50%;
    font-weight: 600;
    border: none;
    border-bottom: solid 3px var(--brand-color-green);
    color: var(--brand-color-orange);
}

.modal-form textarea{
    width: 100%;
    font-weight: 600;
    border: solid 3px var(--brand-color-green);
    border-radius: 10px;
    color: var(--brand-color-orange);
}

.modal-form input::-webkit-input-placeholder,
.modal-form input::-moz-placeholder,
.modal-form input::-moz-placeholder,
.modal-form input::-ms-input-placeholder {
    text-align: center;
}

.modal-body .details{
    border-right: solid 1px #cecece;
}

.modal-body .price-content {
    margin-top: 15px;
    position: absolute;
    bottom: 15px;
}

@media (max-width: 767px) {
    .modal  {
        height: clamp(70%, 700px, 75%);
        padding: 0 15px;
    }

    .modal .modal-container {

    }

    .modal-header{
        padding: 15px 0;
    }

    .modal-body{
        padding: 1rem 0;
    }

    .modal-body .details{
        display: none;
    }

    .modal-form input{
        width: 100%;
    }

    .modal-button {
        width: 100%;
    }
}