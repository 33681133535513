.footer {
    overflow: hidden;
}

.footer {
    padding: 12px 0;
    background: #0b0b13;
}

.footer .nav a {
    color: #fff;
    font-size: 14px;
    margin-right: 17px;
}

.footer p {
    color: #fff;
    font-size: 14px;
    margin: 0;
}