.contact-info {
    padding-top: 35px;
    padding-bottom: 35px;
    border: solid 1px transparent;
}

.contact-info h4,
.consulting-box h4 {
    font-size: 30px;
    color: #fff;
    font-weight: 500;
}

.contact-info p,
.consulting-box p {
    font-size: 16px;
}

.consulting-box p {
    padding-left: 30px;
    padding-right: 30px;
}

.contact-info ul {
    margin: 0;
    padding: 10px 0 0;
    list-style: none;
}

.contact-info li {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
    position: relative;
}

.contact-info li + li {
    padding-top: 20px;
}

.contact-info li i,
.contact-info li .icon {
    width: 30px;
    text-align: center;
    color: #ff9301;
    font-size: 22px;
    font-weight: 600;
}

.contact-info li .text {
    padding-left: 15px;
    color: inherit;
    text-decoration: auto;
}

.theme-light .google-map {
    border: 5px solid #fff;
}

.google-map {
    margin-top: 50px;
}

.google-map iframe {
    width: 100%;
    height: 350px;
    border: none !important;
    border-radius: 10px;
}

.consulting-box {
    text-align: center;
    margin: 0;
    padding: 35px;
    border-radius: 10px;
    background: #0b0b13;
    list-style: none;
    border: solid 1px var(--brand-color-orange-speculative);
}

@media (max-width: 767px) {
    .consulting-box {
        padding: 20px;
    }

    .consulting-box p {
        padding-left: 0;
        padding-right: 0;
        text-align: justify;
    }
}