section#home {
    /*background-image: url('https://nairo.ibthemespro.com/img/slider/home-banner-3.jpg');*/
}

.content-cta-home {
    display: flex;
    align-items: flex-start;
    justify-content: left;
}

.content-cta-home label {
    margin: 0 15px;
    color: var(--brand-color-white);
    line-height: 44px;
}

.content-cta-home .icon {
    margin: 0 5px;
}

@media (max-width: 767px) {
    .content-cta-home {
        flex-direction: column;
        width: 70%;
    }

    .content-cta-home label,
    .content-cta-home .px-btn {
        width: 100%;
        text-align: center;
    }

    .content-cta-home label {margin: 0;}
}