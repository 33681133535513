@import url('https://fonts.googleapis.com/css2? family= Fira+Sans+Condensed:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500 & family= Rubik:ital,wght@0,300;0,500;0,600;0,700;0,900;1,300;1,500;1,600;1,700;1,900 & display=scambia');

:root {
    --brand-color-orange: #ff9301;
    --brand-color-orange-speculative: #ffa500;
    --brand-color-green: #2ea15c;
    --brand-color-green-speculative: #198754;
    --brand-color-dark: #000000;
    --brand-color-dark-light: #0e0f17;
    --brand-color-white: #FFF;
    --brand-color-white-speculative: #f4f7f9;

    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-rgb: 33, 37, 41;
    --brand-font-family-fira: 'Fira Sans Condensed', sans-serif;
    --brand-font-family-rubik: 'Rubik', sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
    --bs-body-font-family: var(--brand-font-family-fira);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}

html {
    overflow-x: hidden;
}

body {
    background: #000;
    color: #0b0b13;
    font-family: var(--bs-body-font-family);
    line-height: 1.7;
    font-size: 15px;
    font-weight: 300;
}

.font-alt {
    font-family: var(--brand-font-family-rubik);
}

img {
    max-width: 100%
}

* {
    outline: none !important;
}

a {
    color: #ff9301;
    transition: .3s;
}

a:hover {
    color: #d77c00;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    color: #0b0b13;
}

mark {
    background-image: linear-gradient(#ff9301, #ff9301);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-color: transparent;
    padding: 0;
    color: currentColor;
}

@media (min-width: 768px) {
    .main-left {
        padding-left: 80px;
    }
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
    }
}

.text-align-center {
    text-align: center !important;
}

.text-align-left {
    text-align: left !important;
}

.section {
    padding: 100px 0;
    position: relative;
}

.dark-bg {
    background: #0e0e17;
}

.theme-dark .meta, .theme-dark p, .theme-dark span {
    color: hsla(0, 0%, 100%, .8);
}

.bg-brand-orange {
    background: var(--brand-color-orange) !important;
}

.color-brand-orange {
    color: var(--brand-color-orange) !important;
}

.button-brand-orange {
    background: var(--brand-color-orange) !important;
    border-color: var(--brand-color-orange-speculative) !important;
    color: var(--brand-color-white) !important;;
}

.bg-brand-green {
    background: var(--brand-color-green) !important;
}

.bg-brand-white {
    background: var(--brand-color-white) !important;
}

.bg-brand-grey {
    background: var(--bs-gray) !important;
}

.bg-brand-dark-light {
    background: var(--brand-color-dark-light) !important;
}

.color-brand-green {
    color: var(--brand-color-green) !important;
}

.color-brand-white {
    color: var(--brand-color-white) !important;
}

.color-brand-dark {
    color: var(--brand-color-dark) !important;
}

.button-brand-green {
    background: var(--brand-color-green) !important;
    border-color: var(--brand-color-green-speculative) !important;
    color: var(--brand-color-white) !important;;
}

.theme-dark .px-btn.px-btn-white {
    background: #fff;
    border-color: #fff;
    color: #0b0b13;
}

.px-btn.px-btn-white {
    background: #fff;
    border-color: #fff;
    color: #0b0b13;
}

.px-btn {
    font-family: var(--brand-font-family-rubik);
    padding: 0 35px;
    line-height: 42px;
    position: relative;
    display: inline-block;
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
    transition: .4s;
    letter-spacing: .55px;
    cursor: pointer !important;
}

.px-btn.btn-md{
    font-size: 42px;
    font-weight: 500;
    line-height: inherit;
}

@media (max-width: 767px) {
    .px-btn.btn-md {
        font-size: 34px;
        font-weight: 500;
        line-height: inherit;
    }
}

/* BANNER */

.banner.banner-two {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
}

.banner {
    position: relative;
    overflow: hidden;
}

.banner .container {
    position: relative;
    z-index: 1;
}

.banner .full-screen {
    min-height: 100vh;
}


.banner .type-box {
    padding-top: 90px;
    padding-bottom: 50px;
}

.banner .type-box .lead {
    color: #fff;
    font-weight: 500;
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.banner .type-box h6 {
    color: #c8c8cb;
}

.banner .type-box h1,
.brand-title-xl {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 15px;
}

.brand-title-md {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.15;
    margin: 0 0 15px;
}

.banner .type-box .desc {
    color: hsla(0, 0%, 100%, .8);
}

.banner .type-box .desc.h-width {
    max-width: 55%;
}

.banner .type-box .desc {
    max-width: 450px;
}

@media screen and (max-width: 1024px) {
    .banner.banner-two .type-box h1,
    .banner.banner-two .type-box h6,
    .banner.banner-two .type-box p {
        color: #fff;
    }
}

@media (max-width: 767px) {
    .section {
        padding: 50px 0;
        position: relative;
    }

    .banner .type-box h6 {
        font-size: 18px;
        margin: 0 0 10px;
    }

    .banner .type-box h1,
    .brand-title-xl {
        font-size: 36px;
    }

    .banner .type-box .lead {
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }

    .banner .type-box .desc.h-width {
        max-width: 70%;
    }

    .banner .type-box {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .brand-title-md {
        font-size: 34px;
    }
}

.about-info .title {
    margin-bottom: 30px;
}

.title {
    margin-bottom: 35px;
}

.theme-light .title h3 {
    color: #0b0b13;
}

.title h3,
.title .has-bullet {
    display: inline-block;
    font-family: var(--brand-font-family-rubik);
    margin: 0;
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 600;
    vertical-align: top;

}

.title h3:after,
.title .has-bullet:after {
    content: "";
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(255, 147, 1, .5);
    position: absolute;
    top: -8px;
    left: -15px;
    z-index: -1;
    -webkit-animation: slide-both-sidee 4s infinite both;
    animation: slide-both-side 4s infinite both;
}

.title .brand-title-xl.has-bullet:after {
    width: 110px;
    height: 110px;
    top: -20px;
    left: 25px;
}

@media (max-width: 991px) {
    .title h3:after,
    .title .has-bullet:after {
        top: -4px;
        left: 0;
    }

    .title .brand-title-xl.has-bullet:after {
        width: 45px;
        height: 45px;
        top: -4px;
        left: 0;
    }
}


.fs-md {
    font-size: 1.05rem;
}