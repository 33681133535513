.about-me .img {
    position: relative;
}

.theme-light .about-me .img-in {
    border: 5px solid #ff9301;
}

.about-me .img-in {
    border-radius: 50%;
    width: 356px;
    height: 358px;
    margin: auto;
    overflow: hidden;
    border: 5px solid #fff;
}

.about-me .img-in img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media (max-width: 767px) {
    .about-me {
        margin-bottom: 55px;
    }

    .about-me .img-in {
        width: 280px;
        height: 280px;
        margin: 0 auto;
    }
}

.about-me .social-icons {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
}

.about-me .social-icons a {
    width: 35px;
    height: 35px;
    line-height: 31px;
    background: #fff;
    border: 2px solid #fff;
    color: #0b0b13;
    text-align: center;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
    transition: all .35s ease;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.theme-light .about-me .social-icons a {
    background: #0b0b13;
    color: #fff;
}

.about-me .social-icons a:first-child,
.about-me .social-icons a:nth-child(5) {
    top: -25px;
}

.about-me .social-icons a:nth-child(2),
.about-me .social-icons a:nth-child(4) {
    top: -8px;
}

.theme-light .about-me .social-icons a {
    background: #0b0b13;
    color: #fff;
}

.theme-light .about-me .info {
    color: #0b0b13;
}

.about-me .info {
    text-align: center;
    padding-top: 40px;
    color: #fff;
}

.about-me .info p {
    margin: 0;
    font-size: 15px;
}

.about-me .info h3 {
    font-weight: 500;
    font-size: 30px;
}

.theme-dark .about-info .info-list label,
.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6 {
    color: #fff !important;
}

.about-info .info-list {
    padding-top: 15px;
}

.about-info .info-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.about-info .info-list li {
    padding: 4px 0;
}

.about-info .info-list label {
    color: #fff;
    margin: 0;
    font-weight: 500;
    padding-right: 10px;
}

.theme-dark .about-info .info-list label,
.theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
    color: #fff !important;
}

.theme-light .about-info .info-list label {
    color: #0b0b13;
}

.about-info .info-list a {
    color: #fff;
    text-decoration: auto;
}

.theme-dark .separated {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.separated {
    background-image: url(../../../public/images/border-dark.png);
}

.separated {
    padding-top: 80px;
    padding-bottom: 80px;
    background-repeat: repeat-x;
    background-size: auto 6px;
    background-position: 50%;
}

.theme-light .feature-box-01 {
    background: #fff;
}

.theme-dark .feature-box-01, .theme-dark .feature-box-02, .theme-dark .testimonial-01 {
    background: #010101;
}

.feature-box-01 {
    background: #0b0b13;
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.feature-box-01:after, .feature-box-01:before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    transition: .5s ease-in-out;
}

.feature-box-01:before {
    top: -220px;
    left: -220px;
    border-radius: 0;
    z-index: -1;
    background: #0b0b13;
}

.feature-box-01:after {
    top: -72px;
    left: -40px;
    border-radius: 50%;
    z-index: -2;
    background: #ff9301;
}

.feature-box-01 .icon {
    line-height: 60px;
    font-size: 40px;
    color: #fff;
}

.feature-box-01 .feature-content {
    padding-top: 50px;
}

.theme-light .feature-box-01 h5 {
    color: #0b0b13;
}

.feature-box-01 h5 {
    margin-bottom: 15px;
    color: #fff;
    transition: .5s ease-in-out;
}

.feature-box-01 p {
    margin: 0;
    transition: .5s ease-in-out;
}

@media (max-width: 767px) {
    .feature-box-01 {
        padding: 20px;
    }

    .feature-box-01:after {
        top: -110px;
        left: -110px;
    }

    .feature-box-01:hover:before {

    }

    .feature-box-01 .icon {
        font-size: 25px;
        line-height: 40px;
    }

    .feature-box-01 .feature-content {
        text-align: justify;
        padding-top: 0px;
    }

    .theme-light .feature-box-01 h5 {
        text-align: right;
        margin-left: 20%;
    }
}

.theme-dark .about-info .info-list label, .theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
    color: #fff !important;
}

.feature-box-02 {
    background: #0b0b13;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    transition: .3s;
}

.feature-box-02 .icon {
    width: 70px;
}

.feature-box-02 .media-body {
    padding-left: 15px;
}

.feature-box-02 h6 {
    color: #fff;
    margin: 0 0 5px;
    font-size: 18px;
}

.feature-box-02 p {
    margin: 0;
    font-size: 14px;
}

.theme-light .feature-box-02 h6 {
    color: #0b0b13;
}

.wrapper {
    width: 150px;
    height: 150px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.wrapper-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
}

.wrapper-item {
    width: inherit;
    height: inherit;
    background: white;
    transform-origin: 50% 100%;
}