.row-features {
    position: relative;
    max-width: 1110px;
    margin: 0 auto;
    padding: 0 30px;
    word-wrap: break-word;
    overflow-wrap: break-word
}

.features__item {
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -30px;
    padding: 50px 20px 40px;
    z-index: 1
}

.features__item:nth-child(2n) {
    background: #f2f5f7
}

.features--bg-none .features__item:nth-child(2n) {
    background: none
}

.features__image-wrap {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 20px -20px 0;
    text-align: center
}

.features__image-wrap img{
    object-fit: contain;
}

.features__content {
    text-align: center
}

.features__title {
    color: #2693ff;
    font-size: 26px;
    line-height: 1;
    margin-bottom: .2666em
}

.features__title--dark {
    color: #222426
}

.features__subtitle {
    font-size: 18px;
    line-height: 1.5;
    color: #879099;
    margin: 0 0 .4em
}

.features__text {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.625;
    color: var(--brand-color-white) !important;
}

.section-features .meta, .section-features p, .section-features span {
    color: var(--brand-color-dark-light);
}

.features__signup-triangle--white {
    display: none
}

.is-white .features__signup-triangle--white {
    display: block
}

.features__signup-triangle--gray {
    display: none
}

.is-gray .features__signup-triangle--gray {
    display: block
}

.features--homepage .features__title {
    color: #acb6bf;
    font-weight: 400
}

.features--homepage .features__title strong {
    font-weight: 700;
    color: var(--brand-color-green);
}


@media (max-width: 760px){
    .features__image-wrap {
    }

    .features__item{
        padding-bottom: 0;
    }

    .features__item:last-of-type{
        padding-bottom: 40px;
    }
}

@media (min-width: 760px) {
    .features__item {
        display: -ms-flexbox;
        display: flex;
        margin: 60px 0;
        padding: 0
    }

    .features__item:nth-child(2n) {
        background: none
    }

    .features__item:nth-child(odd) .features__content {
        -ms-flex-order: 2;
        order: 2;
        margin-right: 0;
        margin-left: 30px
    }

    .features--reversed .features__item:nth-child(odd) .features__content {
        -ms-flex-order: 0;
        order: 0;
        margin-right: 30px;
        margin-left: 0
    }

    .features--reversed .features__item:nth-child(2n) .features__content {
        -ms-flex-order: 2;
        order: 2;
        margin-right: 0;
        margin-left: 30px
    }

    .features__image-wrap {
        width: 50%;
        max-width: 50%
    }

    .features__content {
        text-align: left;
        margin-right: 30px;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .features__title {
        text-align: left;
        font-size: 26px
    }

    .features__subtitle {
        font-size: 18px
    }

    .features__text {
        font-size: 15px
    }

    .features__signup-triangle--white {
        display: block
    }

    .features__signup-triangle--gray,
    .is-gray .features__signup-triangle--gray {
        display: none
    }
}

@media (min-width: 1000px) {
    .features__item {
        margin: 70px 0
    }

    .features__item:nth-child(odd) .features__content {
        margin-left: 50px
    }

    .features__image-wrap {
        margin: 0;
        width: auto
    }

    .features__content {
        margin-right: 50px
    }

    .features__title {
        text-align: left;
        font-size: 30px
    }

    .features__subtitle {
        font-size: 20px
    }

    .features__text {
        font-size: 16px
    }

    .features--reversed .features__item:nth-child(odd) .features__content {
        margin-right: 50px
    }

    .features--reversed .features__item:nth-child(2n) .features__content {
        margin-left: 50px
    }
}

@media (min-width: 1025px) {
    .features__item:nth-child(odd) .features__image-wrap img {
        transform: scale(.8);
        transform-origin: 100% 50%
    }

    .features--reversed .features__item:nth-child(2n) .features__image-wrap img {
        transform: scale(.8);
        transform-origin: 100% 50%
    }

    .features--reversed .features__item:nth-child(odd) .features__image-wrap img,
    .features__image-wrap img {
        transform: scale(.8);
        transform-origin: 0 50%
    }

    .features__image-wrap img {
        opacity: 0;
        transition: opacity 1s, transform .8s;
        will-change: transform, opacity
    }

    .features__image-wrap img.entered {
        transform: translateX(0) !important;
        opacity: 1
    }
}
